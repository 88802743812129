import { defineMessages } from 'react-intl';

export default defineMessages({
  required: {
    id: 'site.components.input.errors.required',
    defaultMessage: 'is a required field.',
    description: 'error message for a required field',
  },
  validUrl: {
    id: 'site.components.input.errors.validUrl',
    defaultMessage: 'must be a valid URL.',
    description: 'error message for a required field',
  },
  validEmail: {
    id: 'site.components.input.errors.validEmail',
    defaultMessage: 'must be a valid email address.',
    description: 'error message for a required field',
  },
});
