import { defineMessages } from 'react-intl';

export default defineMessages({
  emailFieldLabel: {
    id: 'site.pages.apps.quicq.websiteCheck.form.fields.email.label',
    defaultMessage: 'Email',
    description: 'label of the email field',
  },
  emailFieldPlaceholder: {
    id: 'site.pages.apps.quicq.websiteCheck.form.fields.email.placeholder',
    defaultMessage: 'Your email address',
    description: 'placeholder of the email field',
  },
  urlFieldLabel: {
    id: 'site.pages.apps.quicq.websiteCheck.form.fields.url.label',
    defaultMessage: 'URL',
    description: 'label of the url field',
  },
  urlFieldPlaceholder: {
    id: 'site.pages.apps.quicq.websiteCheck.form.fields.url.placeholder',
    defaultMessage: 'URL of your website',
    description: 'placeholder of the url field',
  },
  runTest: {
    id: 'site.pages.apps.quicq.websiteCheck.form.action.runTest',
    defaultMessage: 'Run test',
    description: 'label of the run test button',
  },
});
