import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { useMedia } from 'react-use';
import axios from 'axios';
// import Tippy from '@tippyjs/react';
import { graphql, Link, navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import { AnimatePresence, motion } from 'framer-motion';
import Typography from '../../components/Typography';
import Layout from '../../components/Layout';
import Card from '../../components/Card';
import TestUrlSection from '../../components/TestUrlSection';
import LoadingWrapper from '../../components/LoadingWrapper';
import Button from '../../components/Button';
import Alert from '../../components/Alert/Alert';
import CardBody from '../../components/CardBody';
import Divider from '../../components/Divider';
import Grid from '../../components/Grid';
import formatBytes from '../../utils/formatBytes';
import poll from '../../utils/poll';
import formatLocale from '../../utils/formatLocale';

const variants = {
  visible: {
    opacity: 1,
    transition: {
      duration: 0.2,
      type: 'tween',
      ease: 'easeInOut',
    },
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.2,
      type: 'tween',
      ease: 'easeInOut',
    },
  },
};

const MotionDiv = motion.div;

let cancelToken;

const AnalyseTemplate = ({
  seo,
  feedback,
  form,
  scanning,
  error: errorText,
  results: resultsText,
  locale,
  localizations,
  slug,
}) => {
  const [results, setResults] = useState(null);
  const [shouldRetry, setShouldRetry] = useState(false);
  const [isFetchingResults, setIsFetchingResults] = useState(true);
  const [scanError, setScanError] = useState(null);
  // const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);
  const isMobile = useMedia('(max-width: 767px)');
  const location = useLocation();
  const [currentlyScanningUrl, setCurrentlyScanningUrl] = useState(null);

  const params = new URLSearchParams(location.search);
  const url = params.get('url');
  const existingTestId = params.get('id');

  // const handleStartDownload = () => {
  //   setIsDownloadingPdf(true);
  //
  //   setTimeout(() => {
  //     setIsDownloadingPdf(false);
  //   }, 5000);
  // };

  const handleErrorButtonClick = () => {
    if (scanError === 404) {
      navigate(location.pathname, { replace: true });
      return;
    }

    setShouldRetry(true);
  };

  useEffect(() => {
    let isMounted = true;

    const handleGetNewAnalysis = async () => {
      setIsFetchingResults(true);

      try {
        setCurrentlyScanningUrl(url);
        cancelToken = axios.CancelToken.source();

        const startResponse = await axios(
          `https://quicq-v2-dot-connect-267218.appspot.com/scan?url=${encodeURIComponent(url)}`,
          {
            headers: {
              Accept: 'application/json',
            },
            cancelToken: cancelToken?.token,
          },
        );

        const { data: startOuterData } = startResponse;
        const { data: startData } = startOuterData;
        const { id } = startData;

        if (id) {
          navigate(`${location.pathname}?id=${id}`, {
            replace: true,
          });
        }
      } catch (error) {
        // error
      }
    };

    const handleGetExistingTest = async id => {
      setIsFetchingResults(true);
      setScanError(null);

      try {
        const resultResponse = await poll({
          fn: async () =>
            axios(`https://quicq-v2-dot-connect-267218.appspot.com/scan/${id}`, {
              headers: {
                Accept: 'application/json',
              },
              cancelToken: cancelToken?.token,
            }),
          validate: res => res.data.data.state === 'DONE',
          interval: 2000,
        });

        const { data: resultOuterData } = resultResponse;
        const { data: resultData } = resultOuterData;

        if (isMounted) {
          setResults(resultData);
        }
      } catch (error) {
        if (isMounted) {
          setShouldRetry(false);
          setIsFetchingResults(false);

          setScanError(error?.response?.status || null);
        }
      } finally {
        if (isMounted) {
          setShouldRetry(false);
          setIsFetchingResults(false);
        }
      }
    };

    if (existingTestId) {
      handleGetExistingTest(existingTestId);
    } else if ((url && results?.url !== url) || (url && shouldRetry)) {
      handleGetNewAnalysis();
    } else {
      setIsFetchingResults(false);
    }

    if (!url && !existingTestId) {
      setResults(null);
      setScanError(null);
      cancelToken?.cancel('Url or id cleared');
    }

    return () => {
      isMounted = false;
    };
  }, [url, existingTestId, shouldRetry]);

  useEffect(
    () => () => {
      cancelToken?.cancel('Moved away from route');
    },
    [],
  );

  const alertText = {
    improved: feedback?.improved,
    noImprovement: feedback?.noImprovement,
  };

  const alertData = results?.kb_won > 100 ? alertText.improved : alertText.noImprovement;

  return (
    <Layout location={location} locale={locale} localizations={localizations} slug={slug}>
      <Helmet
        title={seo?.title}
        meta={[
          {
            name: 'description',
            content: seo?.description,
          },
        ]}
      >
        <html lang={formatLocale(locale)} />
      </Helmet>
      <main className="bg-gray-100">
        <section className="bg-afosto-dark">
          <MotionDiv
            initial={{ height: '100vh' }}
            animate={!results || !results?.images?.length > 0 ? { height: '100vh' } : { height: 'auto' }}
            transition={{
              duration: 0.4,
              type: 'tween',
              ease: 'easeInOut',
            }}
          >
            <div className="container mb-sm-16 mb-lg-0  pt-80 pt-sm-112 pb-96 pb-lg-96 h-100">
              <AnimatePresence initial={false} exitBeforeEnter>
                {!url && !existingTestId && (
                  <MotionDiv
                    key="no-url"
                    variants={variants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    className="my-auto"
                  >
                    <TestUrlSection
                      withoutCard
                      formOrientation="column"
                      replaceUrl
                      description={form?.lead}
                      title={form?.title}
                    />
                  </MotionDiv>
                )}

                {isFetchingResults && (!!url || !!existingTestId) && (
                  <MotionDiv
                    key="is-fetching"
                    variants={variants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    className="my-auto"
                  >
                    <div className="d-flex align-items-end">
                      <div className="mr-auto mr-sm-96">
                        <Typography variant={isMobile ? 'h2' : 'h1'} color="white" className="pb-8 px-0">
                          {scanning?.title}
                        </Typography>
                        <Typography variant="subtitle-1" color="gray-400">
                          {currentlyScanningUrl}
                        </Typography>
                      </div>
                      <LoadingWrapper isLoading disableBackground color="white">
                        <div className="py-40 px-40" />
                      </LoadingWrapper>
                    </div>
                  </MotionDiv>
                )}

                {!isFetchingResults && (!!url || !!existingTestId) && (!results || !results?.images?.length > 0) && (
                  <MotionDiv
                    key="is-error"
                    variants={variants}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                    className="my-auto"
                  >
                    <div className="d-flex flex-wrap justify-content-between align-items-end">
                      <div className="mb-32 mb-sm-0">
                        <Typography variant={isMobile ? 'h2' : 'h1'} color="white" className="pb-8 px-0">
                          {errorText?.[typeof scanError === 'number' ? `_${scanError}` : scanError]?.title ||
                            errorText?.default?.title}
                        </Typography>
                        <Typography variant="subtitle-1" color="gray-400">
                          {url}
                        </Typography>
                      </div>
                      <Button onClick={handleErrorButtonClick}>
                        {errorText?.[typeof scanError === 'number' ? `_${scanError}` : scanError]?.button ||
                          errorText?.default?.button}
                      </Button>
                    </div>
                  </MotionDiv>
                )}

                {!isFetchingResults && !!results && results?.images?.length > 0 && (
                  <MotionDiv key="results" variants={variants} initial="hidden" animate="visible" exit="hidden">
                    <div className="d-flex flex-column-reverse flex-sm-row justify-content-between align-items-baseline">
                      <div>
                        <Typography variant={isMobile ? 'h2' : 'h1'} color="white" className="pb-8">
                          {resultsText?.title}
                        </Typography>
                        <Typography variant="subtitle-1" color="gray-400">
                          {results?.url}
                        </Typography>
                      </div>
                      {/* <form */}
                      {/*  action="https://test.quicq.io/pdf/print" */}
                      {/*  method="POST" */}
                      {/*  className="ml-auto mb-48 mb-sm-0 position-relative d-flex align-items-end" */}
                      {/*  onSubmit={handleStartDownload} */}
                      {/* > */}
                      <div className="ml-auto mb-48 mb-sm-0 position-relative d-flex align-items-end">
                        <Link to={location.pathname} className="mt-16 btn btn-outline-light">
                          {resultsText?.changeUrl}
                        </Link>
                      </div>
                      {/* <input type="hidden" name="payload" value={btoa(JSON.stringify(results))} /> */}
                      {/* <Tippy */}
                      {/*  content={resultsText?.downloadStarted} */}
                      {/*  visible={isDownloadingPdf} */}
                      {/*  placement="bottom" */}
                      {/*  theme="light" */}
                      {/* > */}
                      {/*  <Button className="ml-8" size={isMobile ? 'sm' : 'md'}>{resultsText?.button}</Button> */}
                      {/* </Tippy> */}
                      {/* </form> */}
                    </div>
                  </MotionDiv>
                )}
              </AnimatePresence>
            </div>
          </MotionDiv>
        </section>
        <AnimatePresence initial={false}>
          {results?.images?.length > 0 && (
            <MotionDiv
              initial={{ y: '-40%', opacity: 0 }}
              animate={{ y: '-50%', opacity: 1 }}
              exit={{ y: '-40%', opacity: 0 }}
              transition={{ delay: 0.2, type: 'tween', ease: 'easeInOut' }}
              key="alert"
            >
              <section className="container">
                <Alert
                  severity={alertData?.severity}
                  title={alertData?.title}
                  message={alertData?.message}
                  cardProps={{
                    elevation: 2,
                  }}
                />
              </section>
            </MotionDiv>
          )}
          {results?.images?.length > 0 && (
            <MotionDiv
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ delay: 0.2 }}
              key="results"
            >
              {results?.kb_won > 100 && (
                <>
                  <section className="container">
                    <Typography variant="h4" component="h2" className="mb-12">
                      {resultsText?.sections?.performance?.title}
                    </Typography>
                    <Typography variant="body-large" className="mb-32 px-0 col-12 col-md-10 col-lg-7">
                      {resultsText?.sections?.performance?.lead}
                    </Typography>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <Card elevation={0} border className="mb-20 mb-md-0">
                          <CardBody className="p-32">
                            <Typography variant="h6" className="mb-4">
                              {resultsText?.sections?.performance?.wifi?.title}
                            </Typography>
                            <Typography color="black" variant="body" className="mb-24">
                              {resultsText?.sections?.performance?.wifi?.lead}
                            </Typography>
                            <Typography variant="h1" component="div" color="warning" weight="medium">
                              {results?.landline_time_won} {resultsText?.sections?.performance?.wifi?.unit}
                            </Typography>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="col-12 col-md-6">
                        <Card elevation={0} border>
                          <CardBody className="p-32">
                            <Typography variant="h6" className="mb-4">
                              {resultsText?.sections?.performance?.mobile?.title}
                            </Typography>
                            <Typography color="black" variant="body" className="mb-24">
                              {resultsText?.sections?.performance?.mobile?.lead}
                            </Typography>
                            <Typography variant="h1" component="div" color="warning" weight="medium">
                              {results?.['4g_time_won']} {resultsText?.sections?.performance?.mobile?.unit}
                            </Typography>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </section>

                  <Divider className="my-56" />

                  <section className="container">
                    <Typography variant="h4" component="h2" className="mb-12">
                      {resultsText?.sections?.seo?.title}
                    </Typography>
                    <Typography variant="body-large" className="mb-32 px-0 col-12 col-md-10 col-lg-7">
                      {resultsText?.sections?.seo?.lead}
                    </Typography>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <Card elevation={0} border className="mb-20 mb-md-0">
                          <CardBody className="p-32">
                            <Typography variant="h6" className="mb-4">
                              {resultsText?.sections?.seo?.unoptimized?.title}
                            </Typography>
                            <Typography color="black" variant="body" className="mb-24">
                              {resultsText?.sections?.seo?.unoptimized?.lead}
                            </Typography>
                            <Typography variant="h1" component="div" color="warning" weight="medium">
                              {results?.optimised_format_image_count}
                            </Typography>
                          </CardBody>
                        </Card>
                      </div>
                      <div className="col-12 col-md-6">
                        <Card elevation={0} border>
                          <CardBody className="p-32">
                            <Typography variant="h6" className="mb-4">
                              {resultsText?.sections?.seo?.optimized?.title}
                            </Typography>
                            <Typography color="black" variant="body" className="mb-24">
                              {resultsText?.sections?.seo?.optimized?.lead}
                            </Typography>
                            <Typography variant="h1" component="div" color="gray-500" weight="medium">
                              {results?.optimised_format_image_count - results?.image_count}
                            </Typography>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </section>

                  <Divider className="my-56" />

                  <section className="container">
                    <Typography variant="h4" component="h2" className="mb-12">
                      {resultsText?.sections?.traffic?.title}
                    </Typography>
                    <Typography variant="body-large" className="mb-32 px-0 col-12 col-md-10 col-lg-7">
                      {resultsText?.sections?.traffic?.lead}
                    </Typography>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <Card elevation={0} border>
                          <CardBody className="p-32">
                            <Typography variant="h6" className="mb-4">
                              {resultsText?.sections?.traffic?.saved?.title}
                            </Typography>
                            <Typography color="black" variant="body" className="mb-24">
                              {resultsText?.sections?.traffic?.saved?.lead}
                            </Typography>
                            <Typography variant="h1" component="div" color="warning" weight="medium">
                              {formatBytes(results?.kb_won * 1024, 2)}
                            </Typography>
                          </CardBody>
                        </Card>
                      </div>
                    </div>
                  </section>

                  <Divider className="my-56" />
                </>
              )}

              <Grid container component="section" className="pb-40">
                <Typography variant="h4" className="mb-24">
                  {resultsText?.sections?.images?.title}
                </Typography>
                <Grid row>
                  {results?.images?.map(image => (
                    <Grid column xs={12} md={6} key={image?.input?.url} className="pb-30">
                      <Card elevation={0} border className="h-100">
                        <CardBody className="flex-grow-0">
                          <div className="d-flex">
                            <div className="result-thumb flex-shrink-0 mr-20 mr-sm-24">
                              <div className="ratio ratio-1x1">
                                <img
                                  src={`${image?.new_url}?w=64&h=64`}
                                  alt=""
                                  className="thumbnail flex-shrink-0 img-thumbnail ratio-media object-fit-contain"
                                />
                              </div>
                            </div>
                            <div>
                              <Typography variant="h6" component="div" className="word-break-all mb-4">
                                {image?.filename}
                              </Typography>
                              <Typography
                                component="a"
                                href={image?.url}
                                target="_blank"
                                color="gray-800"
                                className="mb-12 word-break-all"
                              >
                                {image?.url}
                              </Typography>
                              <Typography component="div" variant="body-small" color="gray-700">
                                {image?.original_type?.replace('image/', '')} <span className="mx-8">|</span>{' '}
                                {formatBytes(image?.original_size * 1024, 2)}
                              </Typography>
                            </div>
                          </div>
                        </CardBody>
                        <Divider color="gray-300" />
                        {image?.kb_won > 0 && (
                          <CardBody className="d-flex flex-column">
                            <div className="d-flex justify-content-between align-items-baseline mb-16">
                              <Typography weight="medium" color="black" component="div">
                                {resultsText?.sections?.images?.image?.label}
                              </Typography>
                              <Typography weight="medium" color="warning" component="div">
                                {Math.abs(image?.percentage_won)}% {resultsText?.sections?.images?.image?.reduction}
                              </Typography>
                            </div>
                            <Typography
                              component="a"
                              href={image?.new_url}
                              target="_blank"
                              color="gray-800"
                              className="mb-20 mb-sm-24"
                            >
                              {image?.new_url}
                            </Typography>
                            <Typography
                              component="div"
                              variant="body-small"
                              color="gray-700"
                              className="mb-24 mb-sm-20"
                            >
                              {image?.new_type?.replace('image/', '')} <span className="mx-8">|</span>{' '}
                              {formatBytes(image?.new_size * 1024, 2)}
                            </Typography>
                          </CardBody>
                        )}
                        {image?.original_type === image?.new_type && (
                          <CardBody>
                            <div className="d-flex justify-content-between align-items-baseline mb-16">
                              <Typography weight="medium" color="black" component="div">
                                {resultsText?.sections?.images?.image?.alreadyOptimized}
                              </Typography>
                            </div>
                          </CardBody>
                        )}
                        {image?.kb_won <= 0 && (
                          <CardBody>
                            <div className="d-flex justify-content-between align-items-baseline mb-16">
                              <Typography weight="medium" color="black" component="div">
                                {resultsText?.sections?.images?.image?.willNotProfit}
                              </Typography>
                            </div>
                          </CardBody>
                        )}
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </MotionDiv>
          )}
        </AnimatePresence>
      </main>
    </Layout>
  );
};

AnalyseTemplate.propTypes = {
  error: PropTypes.object,
  feedback: PropTypes.object,
  form: PropTypes.object,
  results: PropTypes.object,
  scanning: PropTypes.object,
  seo: PropTypes.object,
  locale: PropTypes.string,
  localizations: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
  slug: PropTypes.string,
};

AnalyseTemplate.defaultProps = {
  error: {},
  feedback: {},
  form: {},
  results: {},
  scanning: {},
  seo: {
    title: '',
    description: '',
  },
  locale: 'en',
  localizations: [],
  slug: undefined,
};

const AnalysePage = ({ data }) => {
  const { frontmatter, fields } = data?.markdownRemark || {};

  return (
    <AnalyseTemplate
      seo={frontmatter?.seo}
      feedback={frontmatter?.analysePage?.feedback}
      form={frontmatter?.analysePage?.form}
      scanning={frontmatter?.analysePage?.scanning}
      error={frontmatter?.analysePage?.error}
      results={frontmatter?.analysePage?.results}
      locale={frontmatter?.locale}
      localizations={frontmatter?.localizations}
      slug={fields?.slug}
    />
  );
};

AnalysePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      fields: PropTypes.object,
    }),
  }),
};

AnalysePage.defaultProps = {
  data: {
    markdownRemark: {
      frontmatter: {},
      fields: {},
    },
  },
};

export default AnalysePage;

export const pageQuery = graphql`
  query analysePage($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        locale
        localizations {
          locale
          slug
        }
        seo {
          title
          description
        }
        analysePage {
          error {
            default {
              button
              title
            }
            _404 {
              button
              title
            }
          }
          feedback {
            improved {
              message
              severity
              title
            }
            noImprovement {
              message
              severity
              title
            }
          }
          form {
            lead
            title
          }
          scanning {
            title
          }
          results {
            button
            changeUrl
            title
            sections {
              images {
                image {
                  alreadyOptimized
                  willNotProfit
                  label
                  link
                  reduction
                }
                title
              }
              performance {
                mobile {
                  lead
                  title
                  unit
                }
                title
                lead
                wifi {
                  lead
                  title
                  unit
                }
              }
              seo {
                lead
                optimized {
                  lead
                  title
                  unit
                }
                title
                unoptimized {
                  lead
                  title
                  unit
                }
              }
              traffic {
                lead
                saved {
                  lead
                  title
                  unit
                }
                title
              }
            }
          }
        }
      }
    }
  }
`;
