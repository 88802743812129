import encodeFormData from './encodeFormData';

export default (data, callback) => {
  const head = document.querySelector('head');
  const script = document.createElement('script');
  let r = false;
  script.type = 'text/javascript';
  script.charset = 'utf-8';
  script.src = `https://afosto.activehosted.com/proc.php?${encodeFormData(data)}&jsonp=true`;
  if (callback) {
    script.onreadystatechange = () => {
      if (!r && (!script.readyState || script.readyState === 'complete')) {
        r = true;
        callback();
      }
    };
    script.onload = script.onreadystatechange;
  }
  head.appendChild(script);
};
