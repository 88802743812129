const poll = async ({ fn, validate, interval, maxAttempts }) => {
  try {
    let attempts = 0;

    const executePoll = async (resolve, reject) => {
      try {
        const result = await fn();
        attempts += 1;

        if (validate(result)) {
          return resolve(result);
        }

        if (maxAttempts && attempts === maxAttempts) {
          return reject(new Error('Exceeded max attempts'));
        }

        return setTimeout(executePoll, interval, resolve, reject);
      } catch (e) {
        return reject(e);
      }
    };

    return new Promise(executePoll);
  } catch (e) {
    return Promise.reject(e);
  }
};

export default poll;
