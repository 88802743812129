import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { navigate } from 'gatsby';
import { addMethod, object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from '@reach/router';
import Card from '../Card';
import CardBody from '../CardBody';
import Grid from '../Grid';
import Typography from '../Typography';
import Input from '../Input';
import Button from '../Button';
import './style.scss';
import submitActiveCampaignForm from '../../utils/submitActiveCampaignForm';
import trackGoogleEvent from '../../utils/trackGoogleEvent';
import translations from './translations';

// eslint-disable-next-line func-names
addMethod(string, 'customUrl', function () {
  return this.test('valid-url', 'Vul een geldige URL in.', value =>
    value.match(
      // eslint-disable-next-line no-useless-escape
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-.][a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
    ),
  );
});

const schema = object().shape({
  test_url: string().customUrl().required(),
  email: string().email().required(),
});

const TestUrlSection = ({
  contentOnly,
  withoutCard,
  formClassName,
  replaceUrl,
  title,
  description,
  className,
  formOrientation,
  titleTypographyProps,
  leadTypographyProps,
}) => {
  const intl = useIntl();
  const location = useLocation();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSubmitTestSite = data => {
    const { test_url: testUrl, email } = data;
    let testValue = testUrl;

    if (testUrl && !testUrl.match(/^https?:\/\//)?.length > 0) {
      testValue = `https://${testUrl}`;
    }

    trackGoogleEvent('event', 'quicq_website_analyse', { test_url: testValue, email });
    submitActiveCampaignForm({ 'field[13]': testValue, ...data });
    return navigate(`${location.pathname}?url=${encodeURIComponent(testValue)}`, {
      replace: replaceUrl,
    });
  };

  if (contentOnly) {
    return (
      <>
        <Typography {...titleTypographyProps}>{title}</Typography>
        <Typography {...leadTypographyProps}>{description}</Typography>
        <form
          className={clsx(`d-flex flex-column test-form ${formClassName} flex-md-${formOrientation}`)}
          onSubmit={handleSubmit(handleSubmitTestSite)}
          noValidate
        >
          <label htmlFor="test-url-input" className="sr-only">
            <FormattedMessage {...translations.urlFieldLabel} />
          </label>
          <Input
            name="test_url"
            type="url"
            id="test-url-input"
            placeholder={intl.formatMessage(translations.urlFieldPlaceholder)}
          />
          <Button className="flex-shrink-0">
            <FormattedMessage {...translations.runTest} />
          </Button>
        </form>
      </>
    );
  }

  if (withoutCard) {
    return (
      <Grid row align="center" direction="column" className={className}>
        <Grid column xs="auto" md={12} lg={9} xl={8}>
          <Typography {...titleTypographyProps}>{title}</Typography>
          <Typography {...leadTypographyProps}>{description}</Typography>
        </Grid>
        <Grid column xs="auto" md={10} lg={6} className="w-100">
          <form
            className={clsx(`d-flex flex-column flex-md-${formOrientation}`)}
            onSubmit={handleSubmit(handleSubmitTestSite)}
            noValidate
          >
            <input type="hidden" ref={register()} name="u" value="6" />
            <input type="hidden" ref={register()} name="f" value="6" />
            <input type="hidden" ref={register()} name="s" />
            <input type="hidden" ref={register()} name="c" value="0" />
            <input type="hidden" ref={register()} name="m" value="0" />
            <input type="hidden" ref={register()} name="act" value="sub" />
            <input type="hidden" ref={register()} name="v" value="2" />
            <Input
              label={intl.formatMessage(translations.urlFieldLabel)}
              showLabel={false}
              name="test_url"
              type="url"
              id="test-url-input"
              placeholder={intl.formatMessage(translations.urlFieldPlaceholder)}
              ref={register()}
              errors={errors}
            />

            <Input
              label={intl.formatMessage(translations.emailFieldLabel)}
              showLabel={false}
              name="email"
              type="email"
              id="test-email-input"
              placeholder={intl.formatMessage(translations.emailFieldPlaceholder)}
              ref={register()}
              errors={errors}
              className="mt-24"
            />
            <Button size="lg" className="flex-shrink-0 mt-32">
              <FormattedMessage {...translations.runTest} />
            </Button>
          </form>
        </Grid>
      </Grid>
    );
  }

  return (
    <div className="overflow-hidden mx-lg-15">
      <section className="container-lg px-0 py-56">
        <Card elevation={0} color="afosto-dark" className="text-white mx-lg-n64" rounded={false}>
          <CardBody className="py-56 py-md-64 py-lg-80">
            <Grid row direction="column" align="center">
              <Grid column xs={12} md={10} lg={9} xl={7}>
                <Typography {...titleTypographyProps}>{title}</Typography>
                <Typography {...leadTypographyProps}>{description}</Typography>
              </Grid>
              <Grid column xs={12} md={10} lg={6} className="w-100">
                <form
                  className={clsx(`d-flex flex-column test-form flex-md-${formOrientation}`)}
                  onSubmit={handleSubmit(handleSubmitTestSite)}
                  noValidate
                >
                  <label htmlFor="test-url-input" className="sr-only">
                    <FormattedMessage {...translations.urlFieldLabel} />
                  </label>
                  <Input
                    name="test_url"
                    id="test-url-input"
                    placeholder={intl.formatMessage(translations.urlFieldPlaceholder)}
                    className="mb-12 mb-md-0"
                    ref={register()}
                    errors={errors}
                  />
                  <Button className="flex-shrink-0">
                    <FormattedMessage {...translations.runTest} />
                  </Button>
                </form>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </section>
    </div>
  );
};

TestUrlSection.propTypes = {
  description: PropTypes.string,
  replaceUrl: PropTypes.bool,
  title: PropTypes.string,
  contentOnly: PropTypes.bool,
  withoutCard: PropTypes.bool,
  className: PropTypes.string,
  formOrientation: PropTypes.oneOf(['row', 'column']),
  titleTypographyProps: PropTypes.object,
  leadTypographyProps: PropTypes.object,
  inputClassName: PropTypes.any,
  formClassName: PropTypes.any,
};

TestUrlSection.defaultProps = {
  description:
    'Met onze gratis website check zie je direct hoeveel jij kan besparen op de afbeeldingen op jouw website. Vul de URL van de pagina in die je wilt testen en ontvang een rapport.',
  replaceUrl: false,
  title: 'Check jouw website',
  contentOnly: false,
  withoutCard: false,
  className: null,
  formOrientation: 'row',
  titleTypographyProps: {
    variant: 'h2',
    weight: 'semi-bold',
    align: 'center',
    className: 'text-white mb-16',
  },
  leadTypographyProps: {
    variant: 'body-large',
    align: 'center',
    className: 'mb-48 mb-md-56',
    color: 'gray-400',
  },
  inputClassName: 'mb-12 mb-md-0',
  formClassName: '',
};

export default TestUrlSection;
